<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <div v-if="errorChainNoTExistInMetamask" class="alert alert-danger">Chain not exist. Try adding the chain first to your wallet.</div>
    <div class="ChainsList">
      <template v-for="(chain,index) in availablesChains" v-bind:key="index">      
        <div v-if="chain.enabled" class="chain d-flex align-items-center justify-content-between" @click="selectChain(chain)">
          <div class="py-2 pl-2">
            <p class="m-0 font-weight-bold">
              <img class="mr-2" width="24" :src="chain.logo"/>{{ chain.name }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>

import axios from 'axios';
import Modal from '../Shared/Modal.vue';
import chains from '../../../data/chains.json'

export default {
  name: 'Home',
  props: [
    'id',
    'modalTitle',
    'classes',
    'index',
  ],
  data() {
    return {
      availablesChains: chains,
      errorChainNoTExistInMetamask : false
    };
  },
  components: {
    Modal
  },
  created() {
  },
  methods: {
    async selectChain(chain){
      this.errorChainNoTExistInMetamask = false;
      try{
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain.chain }], // chainId must be in hexadecimal numbers
        });

        let emitToSend = {
          'chain': chain
        }

        this.$emit('chainSelectedFromList',emitToSend);
        this.closeModal();
      }
      catch(error){
        switch(error.code){
          case 4902:
            this.addChain(chain);
            //this.errorChainNoTExistInMetamask = true;
        }
      }
    },
    closeModal(){
      document.querySelector('#SwapChainList .close').click();
    },
    async addChain(chain){
      const result = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: chain.chain,
          rpcUrls: [chain.rpcUrls],
          chainName: chain.name,
          nativeCurrency: {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol,
            decimals: chain.nativeCurrency.decimals
          },
          blockExplorerUrls: [chain.blockExplorerUrls]
        }]
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .ChainsList{
    .chain{
      cursor:pointer;
      &:hover,&:active,&:focus{
        background:#f5f5f5;
      }
    }
  }
</style>
