import axios from 'axios'
import { ref } from 'vue';
import { cacheAdapterEnhancer } from 'axios-extensions'
import Web3 from 'web3';
import BigNumber from 'bignumber.js'

export default {
  install (app) {
    app.config.globalProperties.$http = axios.create({
      baseURL: '/',
      // cache will be enabled by default
      adapter: cacheAdapterEnhancer(axios.defaults.adapter)
    });
    app.config.globalProperties.$text1 = function () {// Función global 1
      alert('Ejecución exitosa 1');
    };
    app.config.globalProperties.$fixPrice = function (price , decimals){

      let newPrice = parseFloat(price);

      if(newPrice.toString().indexOf('e') > -1){
        newPrice = newPrice.toFixed(20);
      }

      if(newPrice < 1 && newPrice > 0){
        let decimalsDigit = newPrice.toString().split('.')[1].toString().split('');
        let nZeros = 0;
        let intPart = '';
        let flag = false;
        let nDigits = 0;

        decimalsDigit.forEach((digit)=>{
          if(digit == 0 && !flag){
            nZeros++;
          }
          else if(nDigits <= (decimals - 1)){
            intPart += digit.toString()
            nDigits++;
            flag = true;
          }
        })

        if(nZeros > 2){
          let formatPrice = `0.0<sub>${nZeros}</sub>${intPart}`
          return formatPrice;
        }
        else{
          return Number.parseFloat(newPrice).toFixed(decimals);
        }
      }
      else{
        return Number.parseFloat(newPrice).toFixed(decimals);
      }
    };
    app.config.globalProperties.$formatNumber = function (number){
      return new Intl.NumberFormat('es-ES').format(number);
    };
    app.config.globalProperties.$formatRoundNumber = function (number){
      if (number < 1e4) return number;
      if (number >= 1e4 && number < 1e6) return +(number / 1e3).toFixed(1) + "K";
      if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + "M";
      if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + "B";
      if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
    };

    app.config.globalProperties.$isLogged = function (){
      return this.$cookies.get('session') ? true : false;
    };

    app.config.globalProperties.$getSymbolPrice = async function(symbol,source_price,index,id_coinmarketcap){
        return new Promise((resolve, reject) => {
          axios.get(`/cryptos/symbol-price?symbol=${symbol}&source_price=${source_price}&id_coinmarketcap=${id_coinmarketcap}`)
            .then((response)=> {
              if(response.data.body.price.quote){
                resolve(response.data.body.price.quote.USDT.price);
              }
              else if(response.data.body.price){
                resolve(response.data.body.price)
              }
              else {
                resolve(this.$getSymbolPrice(symbol,source_price,index,id_coinmarketcap))
                //resolve(12);
              }
              
            })
            .catch((error) => {
              console.log(error);
              //resolve(this.$getSymbolPrice(symbol,source_price,index,id_coinmarketcap));
            });
        })
    };

    app.config.globalProperties.$menuVisibility = ref(true);

    app.config.globalProperties.$responsiveView = function() {
      this.width = document.documentElement.clientWidth;
      if (this.width < 1280) {
        return true;
      } else {
        return false
      }
    };

    app.config.globalProperties.$connectMetamask = function() {
      if(window.ethereum){
       window.ethereum.request({ method: 'eth_requestAccounts' });
      }
    };

    app.config.globalProperties.$checkMetamask = function() {
      if(window.ethereum){
        return window.ethereum.isConnected();
      }
      else{
        return false;
      }
    };

    app.config.globalProperties.$getAccountsMetamask = async function() {
      this.web3 = new Web3(Web3.givenProvider)
      return new Promise((resolve, reject) => {
        this.web3.eth.getAccounts().then((result) => {
          resolve(result);
        });
      })
    }

    app.config.globalProperties.$convertFromBaseUnits = function(value,decimals) {
      let n = new BigNumber(value / 10 ** decimals)
      return n.toFixed(decimals).replace(/\.?0+$/,"");
    }

    app.config.globalProperties.$convertToBaseUnits = function(value,decimals) {
      let n = new BigNumber(value * 10 ** decimals)
      return n.toFixed(decimals).replace(/\.?0+$/,"")
    }

    app.config.globalProperties.$convertFromWEI = function(value) {
      this.web3 = new Web3(Web3.givenProvider)
      return this.web3.utils.fromWei(value.toString(), 'ether');
    }

    app.config.globalProperties.$convertToWEI = function(value) {
      this.web3 = new Web3(Web3.givenProvider)
      return this.web3.utils.toWei(value.toString(), 'ether');
    }

    app.config.globalProperties.$getToken = async function(token,walletAddress){
      let tokenAddress = token.address;

      // The minimum ABI to get ERC20 Token balance
      let minABI = [
      // balanceOf
        {
          "constant":true,
          "inputs":[{"name":"_owner","type":"address"}],
          "name":"balanceOf",
          "outputs":[{"name":"balance","type":"uint256"}],
          "type":"function"
        },
      // decimals
        {
          "constant":true,
          "inputs":[],
          "name":"decimals",
          "outputs":[{"name":"","type":"uint8"}],
          "type":"function"
        }
      ];
      this.web3 = new Web3(Web3.givenProvider)
      let contract = new this.web3.eth.Contract(minABI,tokenAddress);
      let balance = 0;

      await contract.methods.balanceOf(walletAddress).call().then(
        result => {
          console.log('Balance -> ' + result);
          console.log('Decimals -> ' + token.decimals);
          //balance = this.$convertFromWEI(result);
          balance = this.$convertFromBaseUnits(result,token.decimals);
          console.log(token.symbol)
          console.log(balance)
          console.log(result)
        })

      return balance;
    }

    app.config.globalProperties.$formatDate = function(date) {
      
      let formatDate = new Date(date.toString());
      return formatDate.toLocaleString().split(',')[0];
    }

    /*
    app.config.globalProperties.$getSymbolPriceOld = async function(symbol,source_price,index){
       return new Promise((resolve, reject) => {
          if(source_price =="kucoin"){
            axios.post("http://localhost:3001/connect/kucoin")
              .then((response)=> {
                var ws = new WebSocket(`wss://ws-api-spot.kucoin.com/?token=${response.data.data.token}`)
                ws.onopen = function(){
                  ws.send(JSON.stringify({
                    "id": 1545910660739,
                    "type": "subscribe",
                    "topic": `/market/ticker:${symbol}-USDT`,
                    "response": true
                  }))
                }
                let firstTime = true;
                let vm = this;
                ws.onmessage = function(msg) {
                  var message = JSON.parse(msg.data);
                  if(message.data){
                    resolve(message.data.price);
                  }
                }
              })
          }
          else{
            axios.get(`http://localhost:3001/symbol-price?symbol=${symbol}&source_price=${source_price}`)
              .then((response)=> {
                resolve(response.data.data[0].quote.USDT.price);
              });
          }
        })
    };*/
  }
}