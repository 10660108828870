<template>
  <component :is="layout">
    <slot/>
  </component>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  data() {
    return {
      layoutSelected: this.$route.meta && this.$route.meta.layout && this.$route.meta.layout.name  ? this.$route.meta.layout.name : 'AppLayoutDefault'
    }
  },
  computed: {
    layout () {
      return defineAsyncComponent(() => import(`@/layouts/${this.layoutSelected}.vue`));
    }
  }
}
</script>