<template>
  <div class="row mb-3">
    <div class="col-12 px-0">
      <div class="card rounded-0">
        <div class="card-body pl-2 pr-3 py-3">
          <div class="d-flex justify-content-between align-items-center">
            <button class="navbar-toggler" type="button" @click="changeVisibilityMenu()">
              <span class=""><font-awesome-icon :icon="['fas', 'bars']" /></span>
            </button>
            <div class="d-flex align-items-center">
              <SwapChain class="mr-3 d-none d-sm-block"></SwapChain>
              <ConnectWallet class="mr-4 d-none d-sm-block"></ConnectWallet>
              <div class="dropdown d-flex align-items-center cursor-pointer">
                <div class="dropdown-toggle px-0" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="mr-2"><font-awesome-icon style="scale: 2" class="mr-2" :icon="['far', 'user-circle']" /></span>{{email}}
                </div>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="javascript:void(0)" @click="logout()">Log out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from './ConnectWallet.vue';
import SwapChain from './SwapChain.vue';

export default {
  name: 'Header',
  data() {
    return {
      email: this.$cookies.get('email'),
      menuVisibility: this.$menuVisibility
    }
  },
  components: {
    ConnectWallet,
    SwapChain
  },
  methods: {
    logout(){
      this.$cookies.remove('session');
      this.$cookies.remove('idUser');
      this.$cookies.remove('refresh_token');
      this.$cookies.remove('access_token');
      this.$cookies.remove('email');
      window.location.href = "/login"
    },
    watchVisibilityMenu(){
      this.menuVisibility = this.$menuVisibility.value;
    },
    changeVisibilityMenu(){
      if(this.$menuVisibility.value === true){
        this.$menuVisibility.value = false;
      }
      else{
        this.$menuVisibility.value = true;
      }
      console.log(this.$menuVisibility.value);
    }
  },
  watch: {
    '$menuVisibility.value':{
      handler: function () {
          this.watchVisibilityMenu()
      }, deep: true }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
