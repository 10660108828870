<template>
  <ul id="side-navigation" class="nav flex-column mt-4">
    <template v-for="route in router" v-bind:key="route.name">
      <li class="nav-item" v-if="route.parentPage == '' && route.meta.mainMenu === true && (route.meta.requireAuth === false || (route.meta.requireAuth === true && $isLogged()))">
        <router-link @click="clickPage()" class="nav-link text-white px-0" :to="{name: route.name}"><span class="mr-2"><font-awesome-icon :icon="route.icon"/></span>{{ route.name }}</router-link>
      </li>
    </template>
  </ul>
</template>

<script>

export default {
  name: 'SideNavigation',
  data() {
    return {
      router: this.$router.options.routes
    }
  },
  methods: {
    clickPage(){
      if(document.documentElement.clientWidth < 768){
        this.$menuVisibility.value = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
