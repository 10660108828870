<template>
  <footer id="footer">
    <div class="row h-100">
      <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
        <img src="@/assets/logo-new-dx.png" height="36" alt="Logo Dxboard"/>
      </div>
      <div class="col-12 col-md-6 social-media d-none">
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  created() {
    this.$emit('widgetLoaded');
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import 'node_modules/bootstrap/scss/bootstrap.scss';

  #footer{
    height: 165px;
    align-items: center;
  }

  @include media-breakpoint-down(xs) {
    #footer{
      height: 100px;
    }
  }


</style>
