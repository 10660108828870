import axios from 'axios';
import router from '../router';

export default function setup() {

	axios.interceptors.response.use(null, error => {
		//Agregar errores genéricos de la aplicacion
		switch (error.response.status) {
			case 401: 
				router.push('/login'); 
				break;
			case 403: 
				router.push('/403');
				break;
		}
		return Promise.reject(error);
	});

	axios.interceptors.request.use(function(config) {
		//Agregar Token autorizador
		if(document.cookie.split('session=').length > 1){
			let token = document.cookie.split('session=')[1].split(';')[0];
			config.headers.Authorization = 'Bearer ' + token;
		}
		return config;
	}, function(err) {
		return Promise.reject(err);
	});
}

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;