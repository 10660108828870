<template>
  <div>
		<button v-if="!$checkMetamask() || accounts.length == 0 " class="btn btn-outline-primary" @click="$connectMetamask()"><font-awesome-icon :icon="['fas', 'wallet']" /> Connect Wallet</button>
    <span v-else class="btn btn-outline-primary pl-2"><img src="@/assets/metamask-logo.svg" height="24" alt=""> {{ formatAccount(accounts[0]) }}</span>
  </div>
</template>

<script>
import Web3 from 'web3';
import axios from 'axios';
import Moralis from 'moralis';

export default {
  name: 'Home',
  data() {
    return {
      accounts: '',
      balance: '',
      web3: '',
      tokens: {
        cyt: 0,
      },
      gasPrice: 0,
      listTokens: '',
      token_from:'',
      token_to:'',
      amount_token_from:'10',
      amount_token_to:'',
    };
  },
  created() {
    Moralis.start({
      apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVhNGQ5Mjg5LTdmZTYtNDU5YS1hNTk0LTY1N2NiNjRmNTNlZCIsIm9yZ0lkIjoiMzY4MzE4IiwidXNlcklkIjoiMzc4NTQwIiwidHlwZUlkIjoiMGE5MzFmMDQtYmY3OS00MTZkLWI2ZGMtZjFmMDZjNGVmNTA3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDI2NDM3MDQsImV4cCI6NDg1ODQwMzcwNH0.nxJj7mCC5oVokN2K7M021QOR1DRmRrXjyIu1PPQf53M',
    });
    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result
      });
    }
  },
  updated(){
    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result
      });
    }
  },
  methods: {

    formatAccount(account){
      let formatedAccount = '';

      formatedAccount = account ? account.substring(0,5) : '';
      formatedAccount += account ? '...' : '';
      formatedAccount += account ? account.substring(account.length - 4) : '';

      return formatedAccount;

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
