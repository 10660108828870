import { createStore } from 'vuex'
import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState({
    storage: window.localStorage,
  })],
})