import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js'

const router = createRouter({
// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
	history: createWebHistory(),
	routes, // short for `routes: routes`
	linkActiveClass: "active",
	linkExactActiveClass: "active",
})

router.beforeEach((to, from, next) => {
	//Assign metadata
	document.title = to.meta.title + ' | DXboard';
	document.description = to.meta.description;
	document.keywords = to.meta.keywords;

	if(to.matched.length > 0 && to.matched[0].meta && to.matched[0].meta.requireAuth) {
		if (document.cookie.indexOf('session=') == -1) {
			next({
				name: 'page-unauthorized',
				params: { nextUrl: to.fullPath }
			})
		}
		else{
			let role = localStorage.getItem('role');

			if(to.matched[0].meta.role){
				if(to.matched[0].meta.role === role){
					next();
				}
				else{
					next({
						name: 'page-forbidden',
						params: { nextUrl: to.fullPath }
					})
				}
			}
			else{
				next();
			}
		}
	}
	else{
		next();
	}
})

export default router