import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
//import i18n from './locale';
import router from './router';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import interceptorsSetup from './repositories/interceptor.js';
import globalFunctions from './helpers/global_functions.js';
import globalVars from './config/global_vars.js';
import AppLayout from './layouts/AppLayout'
import 'vue-next-select/dist/index.min.css'
import VueCookies from 'vue3-cookies';

import 'bootstrap';

import './scss/theme.scss';
import './scss/fonts/fontawesome/vue-fontawesome-config';
import './registerServiceWorker'



const app = createApp(App).use(VueCookies).use(store).use(router).use(globalFunctions).use(globalVars);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component('AppLayout', AppLayout);

app.mount('#app')

interceptorsSetup(app)

