<template>
  <div v-if="$checkMetamask() && accounts.length > 0">
    <div class="btn btn-outline-primary pl-2" data-toggle="modal" data-target="#SwapChainList">
      <template v-if="chainSelected">
        <span class="mr-2"> <img width="24" :src="chainSelected.logo" :alt="chainSelected.name" /></span>
        <span class="mr-2"> {{ chainSelected.name }}</span>
        <span class="font-size-075"><font-awesome-icon :icon="['fas', 'chevron-down']"/></span>
      </template>
      <template v-else>
        <span class="mr-2">Switch Network</span>
        <span class="font-size-075"><font-awesome-icon :icon="['fas', 'chevron-down']"/></span>
      </template>
    </div>
    <SwapChainList :id="'SwapChainList'" :modalTitle="'Select a chain'" :classes="'p-0 modal-sm'" @chainSelectedFromList="chainSelectedFromList($event)"></SwapChainList>
  </div>
</template>

<script>
import Web3 from 'web3';
import chains from '../../../data/chains.json'
import SwapChainList from './SwapChainList.vue'

export default {
  name: 'Home',
  data() {
    return {
      chainSelected: '',
      accounts: ''
    }
  },
  components: {
    SwapChainList
  },
  created() {
    if(this.$checkMetamask()){
      
      this.setChain();

      this.$getAccountsMetamask().then((result) => {
        this.accounts = result
      });

      let vm = this;
      window.ethereum.on('chainChanged', function(chainId){
        vm.setChain();
      });
    }
  },
  updated(){
    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result
      });
    }
  },
  methods: {
    async setChain(){
      let currentMetamaskNetwork = await this.getChainId();
      let metamaskChain = chains.filter((chain) => chain.chain == currentMetamaskNetwork && chain.enabled )
      console.log(metamaskChain[0]);
      this.chainSelected = metamaskChain[0];
    },
    getChainId(){
      return window.ethereum.request({
        method: 'eth_chainId',
      });
    },
    chainSelectedFromList(value){
      this.chainSelected = value.chain
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
