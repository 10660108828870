<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="ariaLabelledby" aria-hidden="true" @click="onModalClick" @keydown="onKeyDown">
    <div :class="'modal-dialog modal-dialog-centered ' + classes " role="document">
      <div class="modal-content p-3">
        <div class="modal-header px-0 pt-0">
          <h5 class="modal-title">{{ modalTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onCloseX">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div :class="'modal-body pt-4 px-0 pb-0 ' + class_body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ContractFilters',
  props: [
    'id',
    'ariaLabelledby',
    'modalTitle',
    'class',
    'showFooter',
    'onClose',
    'class_body'

  ],
  data() {
    return {
      classes: this.class,
      footer: this.showFooter == undefined ? true : this.showFooter,
    };
  },
  watch: {
    class() {
      this.classes = this.class;

    }

  },
  methods: {
    onCloseX() {
      if (typeof this.onClose === 'function') {
          this.onClose();
      }
		},
    onModalClick(event) {
      if (event.target === this.$el && typeof this.onClose === 'function') {
          this.onClose();
      }
		},
    onKeyDown(event) {
      if (event.keyCode === 27 && typeof this.onClose === 'function') {
        this.onClose();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-content {
  padding: 30px 30px 20px;
  max-height: 93vh;
  overflow-y: auto;
}
.close {
    font-size: 3rem;
    line-height: 0.4;
    font-weight: normal;
  }

</style>
