<template>
  <div class="container-fluid" :class="{ 'public-page' : $route.meta.isPublicLayout }">
    <template v-if="$route.meta.isPublicLayout">
      <div class="menu-public" v-if="$route.meta.hideHeader !== true">
        <div class="row mt-md-3">
          <div class="div-logo col-12 col-md-3 d-flex align-items-center justify-content-center py-3 py-md-0">
            <img src="@/assets/logo-new-dx.png" height="36" alt="Logo Dxboard"/>
          </div>
          <div class="col-12 col-md-6 d-sm-flex align-items-center justify-content-center d-none d-sm-block">
            <ul class="nav font-family-krona">
              <li class="nav-item px-5">
                <a class="nav-link text-white" href="#features">FEATURES</a>
              </li>
              <li class="nav-item px-5">
                <a class="nav-link text-white" href="#about">ABOUT</a>
              </li>
              <li class="nav-item px-5">
                <a class="nav-link text-white" href="#faqs">FAQS</a>
              </li>
            </ul>
          </div>
          <div class="d-none col-md-3 d-sm-flex align-items-center justify-content-center">
            <router-link href="https://app.dxboard.com" :to="{ name: 'Dashboard'}" class="btn btn-primary btn-cta">OPEN APP</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-3 px-md-0">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div :class="{ 'hide-menu' : !menuVisibility }" class="col-9 col-md-3 col-lg-2 fixed-col-menu rounded-0 border-0 bg-dark pt-3" v-if="$isLogged()">
          <div class="text-center w-100">
            <a class="navbar-brand" href="#"><img alt="Vue logo" height="40" src="@/assets/logo-new-dx.png"></a>
          </div>
          <side-navigation/>
        </div>
        <div class="col-12 main-content" :class="{'col-md-9 offset-9 offset-md-3 offset-lg-2 col-lg-10 pt-0': $isLogged() && menuVisibility, 'col-12': $isLogged() && !menuVisibility}">
          <Header v-if="$isLogged()"></Header>
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </template>
  </div>
  <div class="footer-content">
    <div class="container-fluid footer-bg bg-gray-dark">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>

import Header from './components/Widgets/Header/Header.vue';
import Footer from './components/Widgets/Footer/Footer.vue';
import SideNavigation from './components/Widgets/SideNavigation/SideNavigation.vue';


export default {
  name: 'App',
  data(){
    return {
      menuVisibility: this.$menuVisibility
    }
  },
  components: {
    SideNavigation,
    Header,
    Footer
  },
  async created() {
    if(document.documentElement.clientWidth < 768){
      this.$menuVisibility.value = false;
    }
  },
  watch: {
    '$menuVisibility.value':{
      handler: function () {
          this.watchVisibilityMenu()
      }, deep: true }
  },
  methods:{
    watchVisibilityMenu(){
      this.menuVisibility = this.$menuVisibility.value;
    }
  }
}
</script>

<style lang="scss">
  @import 'node_modules/bootstrap/scss/bootstrap.scss';

  #app{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .menu-public{
    margin-bottom:5rem;

    .nav{
      font-size:12px;
    }
  }

  body{
    background: url(./assets/bg-image-1.png) top 124px left 0 no-repeat, url(./assets/bg-image-2.png) top 450px right 0px no-repeat;
    background-size: 158px, 210px;
  }

  .footer-content{
    margin-top: auto;
  }

  .footer-bg{
    margin-top: 150px;
    background: url(./assets/bg-image-3.png) -5px -125px no-repeat;
    background-size: 220px;
  }

  .div-logo{
    background:transparent;
  }

  @include media-breakpoint-down(xs) {

    body{
      background: url(./assets/bg-image-1.png) top -146px left -74px no-repeat, url(./assets/bg-image-2.png) top 450px right -102px no-repeat;
    }

    .footer-bg{
      margin-top:50px;
      background: url(./assets/bg-image-3.png) -175px no-repeat;
    }

    .div-logo{
      background:rgba(11, 12, 14,0.9);
    }
  }


</style>
